import { LotAction, UPDATE_LOTS } from "../actions/LotActions";
import { PlayerDTO } from "./FreeAgentReducer";

export interface Bid {
    bidId?: number
    bidLength: number
    bidSalary: number
    ownername: string
    ownerId: number
    expires?: Date
    lotId?: number
    leagueId: number
    player: PlayerDTO
}
export interface Lot {
    lotId: number
    bid?: Bid
    newNom: boolean
    nominatedBy: number
    isFresh?: boolean
    leagueId: number
}

const defaultState = [] as Lot[]//Array.from(Array(13).keys()).map(l => { return { lotId: l + 1, newNom: false}}) as Lot[]


export const lotReducer = (state = defaultState, action: LotAction): Lot[] => {
    switch (action.type) {
        case UPDATE_LOTS:
            return action.payload;
        default:
            return state;
    }
}