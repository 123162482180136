import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitWin } from "../../redux/actions/LotActions";
import { Lot } from "../../redux/reducers/LotReducer";
import { ownerMap } from "../../services/Common";
import { RootState } from "../../redux/reducers/RootReducer";

export interface ExpirationObj {
    days: number
    hours: number
    minutes: number
    seconds: number
}


export const Timer = ({ endTime, lot }: { endTime?: Date, lot: Lot }): JSX.Element => {
    const dispatch = useDispatch();
    const [remaining, setRemaining] = useState<ExpirationObj>();
    const [preventClockTick, setPreventClockTick] = useState<boolean>(false);
    const { isConnected } = useSelector((state: RootState) => state.signalR)
    const calculateTimeLeft = (endTime: Date | undefined) => {
        if (!endTime) return
        const now = new Date();

        let utcNow = Date.UTC(
            now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
            now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
        let utcEnd = Date.UTC(endTime.getUTCFullYear(), endTime.getUTCMonth(), endTime.getUTCDate(),
            endTime.getUTCHours(), endTime.getUTCMinutes(), endTime.getUTCSeconds(), now.getUTCMilliseconds())

        let difference = utcEnd - utcNow;

        if (utcEnd <= utcNow && isConnected ) {
            setPreventClockTick(true)
            if(lot.bid) {
                dispatch(submitWin(lot.bid))
            }
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            } as ExpirationObj;
        }

        let timeLeft: ExpirationObj = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60) + 1
        };
        return timeLeft;
    }


    const getTimerColor = (): string => {
        if (!endTime || !remaining) return 'linear-gradient(90deg, rgba(192,192,192,0) 0%, rgba(192,192,192.73) 50%, rgba(192,192,192,0) 100%)'
        if (remaining.hours < 1) return 'linear-gradient(90deg, rgba(255,0,0,0) 0%, rgba(255,0,0,0.73) 50%, rgba(255,0,0,0) 100%)'
        if (remaining.hours < 3) return 'linear-gradient(90deg, rgba(255,153,0,0) 0%, rgba(255,153,0,0.73) 50%, rgba(255,153,0,0) 100%)'
        if (remaining.hours < 6) return 'linear-gradient(90deg, rgba(255,255,51,0) 0%, rgba(255,255,51,0.73) 50%, rgba(255,255,51,0) 100%)'
        else return 'linear-gradient(90deg, rgba(51,204,0,0) 0%, rgba(51,204,0,0.73) 50%, rgba(51,204,0,0) 100%)'
    }
    useEffect(() => {
        const timer = setInterval( () => {
            if (endTime && !preventClockTick) setRemaining(calculateTimeLeft(endTime));
        }, 1000);
        return () => clearInterval(timer);
    }, [endTime, preventClockTick, lot, dispatch, setPreventClockTick]);

    return (
        <div className="mb-1 flex content-center" style={{ background: getTimerColor() }}>
            {endTime && remaining && 
                <div className="text-xl text-center w-full">{remaining.days}:{remaining.hours.toString().padStart(2, '0')}:{remaining.minutes.toString().padStart(2, '0')}:{remaining.seconds.toString().padStart(2, '0')} 
                </div>}
        </div>
    );
}