

export const TermsOfService = (): JSX.Element => {

    return (
    <div style={{padding: 20}}>
        <h1 style={{fontSize: 26, marginBottom: 16}}>Terms of Service - StanFan</h1>

        <p><strong>1. Acceptance of Terms</strong></p>
        <p className='ml-5'>By accessing or using the StanFan website, you agree to be bound by these Terms of Service and all applicable laws and regulations.</p>
        <p><strong>2. User Accounts and Security</strong></p>
        <p className='ml-5'>a. You are responsible for maintaining the security of your account and password.</p>
        <p className='ml-5'>b. You must notify us immediately of any unauthorized use of your account or any other breach of security.</p>
        <p><strong>3. User Conduct</strong></p>
        <p className='ml-5'>a. Do not engage in any unlawful or harmful activities on the website.</p>
        <p className='ml-5'>b. Respect the privacy and rights of other users.</p>
        <p><strong>4. Privacy</strong></p>
        <p className='ml-5'>a. Refer to the Privacy Policy for information on how we collect, use, and disclose user information.</p>
        <p><strong>5. Intellectual Property</strong></p>
        <p className='ml-5'>a. The content on this website, including text, graphics, and other materials, is owned by StanFan</p>
        <p><strong>6. Modifications</strong></p>
        <p className='ml-5'>a. We reserve the right to modify or terminate the service at any time without notice.</p>
        <p><strong>7. Limitation of Liability</strong></p>
        <p className='ml-5'>a. We are not liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of this website.</p>
        <p><strong>8. Governing Law</strong></p>
        <p className='ml-5'>a. These Terms of Service are governed by and construed in accordance with the laws of The Commonwealth of Pennsylvania.</p>
        <p><strong>9. Contact Information</strong></p>
        <p className='ml-5'>a. For questions about these Terms of Service, please contact Stanman519@gmail.com.</p>
    </div>

    )
};